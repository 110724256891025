export function count_decimals(number) {
  if (
    typeof number !== "number" ||
    Number.isNaN(number) ||
    !Number.isFinite(number)
  )
    throw new Error("Invalid argument 'number'");
  // A decimal number is composed by an integer and a fractional part separated
  // by a decimal point.
  let fractional_part;
  [, fractional_part] = number.toString().split(".");
  return fractional_part ? fractional_part.length : 0;
}
