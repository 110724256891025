import moment from "moment";

import { count_decimals } from "@/utils/numbers";

/*
  INDEX (function_name)
  ---------------------

  Required field (field_req)

  Input type text
  - Password (valid_password)
  - Email (valid_email)
  - Phone (valid_phone)
  - URLs (url_without_protocol)
  - Text length (txt_min_2_char)

  Input type number 
  - (is_number)
  - Minimum value (val_min_0)
  - Minimum value (val_min_001)
  - Minimum value (val_min_1)
  - Decimals (count_2_decimals)
  - Decimals (count_0_decimals)

  Array (is_not_empty_array)

  Note that fields may be returned from db as 'number', but
  form can transform them into string (nature of HTML input)  
*/

export function field_req(v) {
  const v_is_valid =
    (typeof v === "string" && v.trim().length > 0) || typeof v === "number";
  return v_is_valid || "This field is required";
}

export function is_number(v) {
  // Ideally, to use with input 'type="number"'
  return !isNaN(v) || "This field requires a number";
}

export function is_not_empty_array(v) {
  // Use carefully, will force field to be required
  return v.length > 0 || "Please select at least one element";
}

export function valid_password(v) {
  if (!v) return true;
  return (
    (v && v.trim().length >= 8) || "Password must be at least 8 characters long"
  );
}

export function valid_filename(v) {
  if (!v) return true;
  const name_regex = /^[^\\/:*?".<>|]+$/;
  return (v && name_regex.test(v)) || "Must be a valid file name ";
}

export function passwords_match(v, password) {
  if (!v) return true;
  return v === password || "Passwords must match";
}

export function valid_email(v) {
  if (!v) return true;
  return /\S+@\S+\.\S+/.test(v) || "Email must be valid";
}

export function valid_phone(v) {
  if (!v) return true;
  const v_fmt = v.replace(/\s/g, "");
  return /\+?(\d[0-9]{6,15})/.test(v_fmt) || "Phone must be valid";
}

export function url_without_protocol(v) {
  return (
    !/^(http|https):\/\//.test(v) || "Please remove 'http://' or 'https://'"
  );
}

export function txt_min_2_char(v) {
  if (!v) return true;
  return (
    (v && v.trim().length >= 2) || "Text must be at least 2 characters long"
  );
}

export function length_min_max(v, min, max) {
  if (!v) return true;
  return (
    (v.length >= min && v.length <= max) ||
    (min === max
      ? `This field's value must be ${min} characters long`
      : `This field's value must be between ${min} and ${max} characters long`)
  );
}

export function length_max(v, max) {
  if (!v) return true;
  return (
    v.length <= max || `The value must not be longer than ${max} characters`
  );
}

export function val_min_0(v) {
  if (!v) return true;
  return v >= 0 || "Min value is 0";
}

export function val_min_001(v) {
  if (!v) return true;
  return v >= 0.01 || "Min value is 0.01";
}

export function val_min_7(v) {
  if (!v) return true;
  return v >= 7 || "Min value is 7";
}

export function val_max_21(v) {
  if (!v) return true;
  return v <= 21 || "Max value is 21";
}

export function val_0_7_21(v) {
  if (!v) return true;
  v = Number(v);
  if (v === 0) return true;
  if (v >= 7 && v <= 21) return true;
  return "Please type 0 or value between 7-21";
}

export function val_min_1(v) {
  if (!v) return true;
  return v >= 1 || "Min value is 1";
}

export function val_max_100(v) {
  if (!v) return true;
  return v <= 100 || "Max value is 100";
}

export function count_0_decimals(v) {
  // Only use with input 'type="number"'
  // You may use it alongside is_number rule
  return count_decimals(Number(v)) === 0 || "Value does not accept decimals";
}

export function count_2_decimals(v) {
  // Only use with input 'type="number"'
  // You may use it alongside is_number rule
  return count_decimals(Number(v)) <= 2 || "Value only accepts two decimals";
}

export function all_emails_valid(emails) {
  const emails_valid = emails.every((email) => valid_email(email) === true);
  if (!emails_valid) return "All emails have to be in correct form";
  return emails_valid;
}

export function number_between(v, min, max) {
  return v
    ? (Number(v) >= min && Number(v) <= max) || `Minimum ${min}, maximum ${max}`
    : true;
}

export function maximum_array_length(v, length) {
  return v.length <= length || `You can choose a maximum of ${length} options`;
}

export function minimum_array_length(v, length) {
  return (
    v.length >= length || `You need to choose a minimum of ${length} options`
  );
}

export function datepicker_range_valid(start, end, format = "YYYY-MM-DD") {
  const start_moment = moment(start, format);
  const end_moment = moment(end, format);
  return (
    start_moment.isBefore(end_moment) ||
    start_moment.isSame(end_moment) ||
    "The starting date must be earlier or equal"
  );
}

export function must_start_with_https(v) {
  return v.startsWith("https://") || 'Link must start with "https://"';
}

export const rules_max_days = [is_number, (v) => number_between(v, 1, 14)];

export const rules_cost = [field_req, is_number, val_min_0, count_2_decimals];

export const rules_postcode = [is_not_empty_array];

export const rules_cost_min_001 = [
  field_req,
  is_number,
  val_min_001,
  count_2_decimals,
];
